import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "안락사, 개인 선택권인가?",
  "slug": "data5",
  "date": "2022-03-02T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb05.jpg",
  "featuredImg": "../images/featured/featured05.jpg",
  "summary": "한국인은 안락사 허용에 대해 어떻게 생각할까? 찬성과 반대의 이유는 무엇일까? 안락사를 허용해도 되는 경우는 어떤 때일까? 너 나아가 죽음에 대해 어떻게 받아 들일까?",
  "tags": ["정책", "공익", "공공성"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`2018년 2월 4일, 우리나라에서는 '연명 의료 결정법'이 시행됐다. '존엄사법'으로 불리는 이 법률은 임종에 가까운 환자가 본인 의지나 가족의 동의로 인공호흡기 등의 연명의료를 중단할 수 있게 한 것이다.`}<br parentName="p"></br>{`
`}{`하지만 이것은 매우 소극적인 차원에서의 선택으로 안락사와는 다르다. 안락사는 영양분 공급을 중단하거나 약물을 투입하는 등 환자의 죽음을 인위적으로 앞당기는 행위를 말한다. 조력 자살을 포함한 안락사를 허용하는 국가는 스위스, 네덜란드, 룩셈부르크, 벨기에 등 7개국이다.`}<br parentName="p"></br>{`
`}{`한국인은 안락사 허용에 대해 어떻게 생각할까? 찬성과 반대의 이유는 무엇일까? 안락사를 허용해도 되는 경우는 어떤 때일까? 너 나아가 죽음에 대해 어떻게 받아 들일까?`}</p>
    <h2>{`안락사 허용에 찬성하십니까?`}</h2>

    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`한국인 10명 중 8명이 안락사 허용에 찬성하는 것으로 나타났다. 고통스러운 치료로 생명을 연장하기보다는 편안한 죽음을 맞이하는 게 더 낫다고 여기는 사람의 비중이 그만큼 높은 것이다.`}<br parentName="p"></br>{`
`}{`안락사 찬성 응답자를 살펴보면 남성(88.1%)이 여성(73.3%)보다 더 많았고 20대(91.5%)와 50대(83.8%), 60대 이상(79.9%)에서 찬성 비율이 높았다. 종교별로는 불교(84.3%)에서 찬성 비율이 높지만, 가톨릭(75.1%)과 개신교(71.6%)도 절반이 훨씬 넘는다. 가톨릭과 개신교는 안락사에 대해 공식적으로 부정적 의견이다. 교인들이 평안한 죽음에 대해 분명한 개인적 의견을 지니고 있다고 봐야 할 것이다.`}</p>
    <h3>{`안락사 허용에 찬성하거나 반대하는 이유`}</h3>
    <p>{`안락사 허용을 찬성하는 이유로는 죽음 선택도 인간의 권리(52.0%)라는 응답이 가장 많았다. 특히 20대(67.3%)와 30대(60.2%)에서 이런 생각이 많았다. 젊은 세대는 안락사를 선택 가능한 또 다른 죽음의 형태로 받아들이고 있다. 병으로 인한 고통을 줄일 수 있기 때문(34.9%)이라는 점도 안락사 찬성의 주된 이유다.`}<br parentName="p"></br>{`
`}{`안락사를 반대하는 가장 큰 이유는 경제적 이유로 안락사에 내몰리거나 범죄에 악용될 수 있다(41.6%)는 것이다. 생명 경시 풍조 만연 (31.1%)과 환자의 회복 가능성을 원천적으로 차단(15.4%)하는 것도 중요한 반대 이유다.`}</p>
    <h3>{`안락사를 허용할 수 있는 환자의 상태`}</h3>
    <p>{`안락사 허용을 찬성하는 이유로는 죽음 선택도 인간의 권리(52.0%)라는 응답이 가장 많았다. 특히 20대(67.3%)와 30대(60.2%)에서 이런 생각이 많았다. 젊은 세대는 안락사를 선택 가능한 또 다른 죽음의 형태로 받아들이고 있다. 병으로 인한 고통을 줄일 수 있기 때문’(34.9%)이라는 점도 안락사 찬성의 주된 이유다.`}<br parentName="p"></br>{`
`}{`안락사를 반대하는 가장 큰 이유는 경제적 이유로 안락사에 내몰리거나 범죄에 악용될 수 있다(41.6%)는 것이다. 생명 경시 풍조 만연 (31.1%)과 환자의 회복 가능성을 원천적으로 차단(15.4%)하는 것도 중요한 반대 이유다.`}</p>
    <h3>{`품위 있는 죽음을 위해 필요한 것`}</h3>
    <p>{`품위 있는 죽음을 위한 요건이 무엇인지 물었다. ‘가족 등 주변에 부담 주지 않는다’가 48.4%로 가장 높은 비중을 차지했다. 그다음은 임종 순간을 스스로 결정 하는 것(18.7%), 고통을 느끼지 않는 것(18.4%)이 꼽혔다.`}<br parentName="p"></br>{`
`}{`여론조사를 수행한 김대진 조원씨앤아이 대표는 “과거에는 존엄사와 안락사가 살인 또는 자살과 같은 개념으로 인식됐지만, 이번 조사 결과 국민의 생각이 변했다는 게 나타났다”고 평가했다. “젊은 층은 삶과 죽음을 스스로 결정할 수 있다는 주체적 시각, 노년층은 가족에게 부담을 주지 않고 싶다는 이유로 선택적 죽음을 받아들이려는 경향이 보인다”고 말했다.`}</p>
    <h6>{`*2019년 2월 13~14일 유무선 혼용 ARS 방식으로 진행됐다. 전국 성인남녀 1,000명을 대상으로 했다. 95% 신뢰수준에 표본오차는 ±3.1%p다. <서울신문>과 공공의창이 함께 설계했으며 수행은 여론조사기관 조원씨앤아이가 맡았다. 황규성 한국엠바밍 대표(전 을지대학교 장례지도학과 교수)가 자문을 제공했다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      